/** @jsxImportSource @emotion/react */

// import placeholder from "../../../../../images/placeholder.png"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useTheme } from "../../../../design/hooks/useTheme"
import { P } from "../../../../design/system/P"
import { Button } from "../../../../design/system/button/Button"
import { H1 } from "../../../../design/system/headers/H1"
import { CloseableModal } from "../../../../modal/CloseableModal"
import "react-lazy-load-image-component/src/effects/blur.css"

type Props = {
  description: string
  name: string
  highResPhoto: string
  onClose: () => void
}

export const VoiceModal: React.FC<Props> = ({ description, name, highResPhoto, onClose }) => {
  const { colors } = useTheme()

  return (
    <CloseableModal maxWidth={900} onClose={onClose}>
      <H1 color={colors.primary[300]} textAlign="left">
        {name}
      </H1>
      <div
        css={{
          display: "flex",
          span: {
            marginRight: 20,
            float: "left"
          }
        }}
      >
        <P justify>
          <LazyLoadImage
            alt="Face"
            css={{
              marginRight: 10,
              width: "42vw",
              height: "42vw",
              maxWidth: 400,
              maxHeight: 400,
              float: "left"
            }}
            effect="blur"
            src={
              highResPhoto === "placeholder"
                ? require("../../../../../images/placeholder.png")
                : require(`../../../../../images/voices/highres${highResPhoto}.jpg`)
            }
          ></LazyLoadImage>
          {description}
        </P>
      </div>
      <Button float="right" intent="Primary" onClick={onClose}>
        ZAMKNIJ
      </Button>
    </CloseableModal>
  )
}
