/** @jsxImportSource @emotion/react */

import { injectGlobal } from "@emotion/css"
import { LandingGraphic } from "./sections/LandingGraphic"
import { MainAboutUs } from "./sections/MainAboutUs"
import { MainVideo } from "./sections/MainVideo"

injectGlobal`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Lato:wght@300;400;700&display=swap');
`

export const MainPage: React.FC = () => (
  <>
    <LandingGraphic />
    <MainAboutUs />
    <MainVideo />
  </>
)
