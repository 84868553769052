/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { EventDefinition } from "../../../modules/domain/events/EventDefinition"
import { getEvents } from "../../../modules/domain/events/actions"
import { MainState } from "../../../modules/state/MainState"
import { useTheme } from "../../design/hooks/useTheme"
import { H1 } from "../../design/system/headers/H1"
import { EventCard } from "./EventCard"

const mapState = (state: MainState): { hasNextPage: boolean; events: EventDefinition[] } => state.domain.events
const INITIAL_PAGINATION = 6

export const EventsPage: React.FC = () => {
  let PAGINATION = INITIAL_PAGINATION
  const eventsPage = useSelector(mapState, shallowEqual)
  const { colors } = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvents({ currentState: [], first: INITIAL_PAGINATION, skip: 0 }))
  }, [dispatch])

  const fetchMoreEvents = (): void => {
    dispatch(getEvents({ currentState: eventsPage.events, first: PAGINATION + PAGINATION, skip: PAGINATION }))
    PAGINATION += PAGINATION
  }

  return (
    <section css={{ width: "100%" }}>
      <H1 color={colors.primary[300]} textAlign="left">
        KONCERTY - TU USŁYSZYSZ NAS NA ŻYWO
      </H1>
      <div
        css={{
          width: "100%",
          ".infinite-scroll-component__outerdiv": {
            width: "100%",
            ".infinite-scroll-component": {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "60px"
            }
          }
        }}
      >
        <InfiniteScroll
          dataLength={eventsPage.events.length}
          next={fetchMoreEvents}
          hasMore={eventsPage.hasNextPage}
          loader={<p>Ładowanie...</p>}
          scrollThreshold={0.8}
        >
          {eventsPage.events.map(({ title, date, poster, id }) => (
            <EventCard title={title} date={date} poster={poster} id={id} key={id} />
          ))}
        </InfiniteScroll>
      </div>
    </section>
  )
}
