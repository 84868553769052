import { ChoirAboutUs } from "./sections/ChoirAboutUs"
import { IAltos } from "./sections/IAltos"
import { IIAltos } from "./sections/IIAltos"
import { IISopranos } from "./sections/IISopranos"
import { ISopranos } from "./sections/ISopranos"

export const ChoirPage: React.FC = () => (
  <>
    <ChoirAboutUs />
    <ISopranos />
    <IISopranos />
    <IAltos />
    <IIAltos />
  </>
)
