/** @jsxImportSource @emotion/react */

import choir from "../../../../images/choir.jpg"
import conductor from "../../../../images/conductor.jpg"
import { useRoutes } from "../../../routes/hooks/useRoutes"
import { useScreen } from "../../../screen/hooks/useScreen"
import { MainAboutUsCard } from "./parts/MainAboutUsCard"
import { MainSection } from "./parts/MainSection"

export const MainAboutUs: React.FC = () => {
  const routes = useRoutes()
  const { window } = useScreen()

  return (
    <MainSection sectionTitle="OTO MY!">
      <div
        css={{
          display: "flex",
          flexDirection: window.width > 575 ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: 10
        }}
      >
        <MainAboutUsCard image={choir} route={routes.choir()} subtitle="poznaj nas" title="ZESPÓŁ" />
        <MainAboutUsCard image={conductor} route={routes.conductor()} subtitle="poznaj ją" title="DYRYGENTKA" />
      </div>
    </MainSection>
  )
}
