/** @jsxImportSource @emotion/react */
import { PropsWithChildren, useEffect, useState } from "react"
import { useTheme } from "../design/hooks/useTheme"
import { useScreen } from "../screen/hooks/useScreen"

type Props = {
  maxWidth?: number
}

const animationDuration = 250

export const ModalBox: React.FC<PropsWithChildren<Props>> = ({ children, maxWidth }) => {
  const { window } = useScreen()
  const { colors } = useTheme()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => setVisible(true))
  }, [])

  return (
    <div
      css={{
        backgroundColor: colors.white,
        borderRadius: 8,
        boxShadow: "0px 4px 8px -2px rgba(17, 24, 39, 0.1), 0px 2px 4px -2px rgba(17, 24, 39, 0.06)",
        opacity: visible ? 1 : 0,
        padding: 30,
        transitionDuration: `${animationDuration}ms`,
        transitionProperty: "margin-top, opacity",
        transitionTimingFunction: "ease-in-out",
        marginTop: visible && window.width >= 480 ? 143 : 0,
        maxWidth
      }}
    >
      {children}
    </div>
  )
}
