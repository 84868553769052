/** @jsxImportSource @emotion/react */
import { FormikHelpers } from "formik"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { SendContactEmailData } from "../../../modules/email/EmailService"
import { sendContactEmail } from "../../../modules/email/actions"
import { Messenger } from "../../messenger/Messenger"
import { ContactInfoSection } from "./ContactInfoSection"
import { ContactPageEmailForm } from "./ContactPageEmailForm"

export const ContactPage: React.FC = () => {
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    (data: SendContactEmailData, formHelpers: FormikHelpers<SendContactEmailData>) => {
      dispatch(
        sendContactEmail({
          data,
          formHelpers
        })
      )
    },
    [dispatch]
  )

  return (
    <section
      css={{ width: "100%", display: "flex", flexWrap: "wrap", paddingTop: 20, flexDirection: "row", justifyContent: "space-between" }}
    >
      <ContactInfoSection />
      <ContactPageEmailForm onSubmit={onSubmit} />
      <Messenger />
    </section>
  )
}
