/** @jsxImportSource @emotion/react */
import { intlFormat } from "date-fns"
import { Link } from "react-router-dom"
import { useTheme } from "../../design/hooks/useTheme"
import { P } from "../../design/system/P"
import { Button } from "../../design/system/button/Button"
import { H3 } from "../../design/system/headers/H3"
import { useRoutes } from "../../routes/hooks/useRoutes"

type Props = {
  id: string
  title: string
  date: string
  poster?: string | null
}

export const EventCard: React.FC<Props> = ({ id, title, date, poster }) => {
  const { colors } = useTheme()
  const routes = useRoutes()

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        margin: 15,
        width: 350,
        height: "fit-content",
        border: "1px solid rgba(0,0,0,.125)",
        borderRadius: "0.25rem",
        p: {
          textAlign: "end",
          fontSize: "15px",
          marginBottom: "10px",
          marginRight: "10px"
        }
      }}
    >
      {poster && (
        <img
          css={{
            width: "100%",
            borderTopLeftRadius: "calc(0.25rem - 1px)",
            borderTopRightRadius: "calc(0.25rem - 1px)"
          }}
          src={poster}
          alt="Event"
        />
      )}
      <div>
        <H3 strong={true} color={colors.black} textAlign="center">
          {title}
        </H3>
        <P>
          {intlFormat(
            new Date(date),
            {
              day: "numeric",
              month: "long",
              year: "numeric"
            },
            {
              locale: "pl"
            }
          )}
        </P>
      </div>
      <Link to={routes.events.details(id)} css={{ marginTop: "auto", padding: 15 }}>
        <Button intent="Primary">SZCZEGÓŁY</Button>
      </Link>
    </div>
  )
}
