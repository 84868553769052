import { Theme } from "../design/Theme"
import { Intent } from "./Intent"

export const getBackgroundColorForIntent = (intent: Intent, useAltShade: boolean, emphasise: boolean, colors: Theme["colors"]): string => {
  const shade = useAltShade ? 100 : emphasise ? 500 : 300

  switch (intent) {
    case "Primary":
      return colors.primary[shade]
    case "Danger":
      return colors.danger[300]
    case "Success":
      return colors.success[300]
    default:
      return "inherit"
  }
}
