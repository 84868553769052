export const unknownErrorToString = (error: unknown): string => {
  if (typeof error === "string") {
    return error
  } else if (error != null && typeof error === "object") {
    if (typeof (error as Error).message === "string") {
      return (error as Error).message
    } else {
      return "Wystąpił nieznany błąd, przepraszamy"
    }
  } else {
    return `${error}`
  }
}
