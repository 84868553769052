/** @jsxImportSource @emotion/react */
import { useTheme } from "../../design/hooks/useTheme"
import { P } from "../../design/system/P"
import { H1 } from "../../design/system/headers/H1"

export const ContactInfoSection: React.FC = () => {
  const { colors } = useTheme()
  return (
    <div css={{ width: 395 }}>
      <H1 color={colors.primary[300]} textAlign="left">
        ZAPRASZAMY DO NAS!
      </H1>
      <P>Spotykamy się w poniedziałki o 18.00 w sali przy ul. Matejki 6 we Wrocławiu. Przyjdź, posłuchaj, może zechcesz do nas dołączyć?</P>
      <P>Dyrygentka:</P>
      <P strong>Izabela Polakowska-Rybska</P>
      <P>tel.: 668-818-297</P>
      <P>Prezes:</P>
      <P strong>Joanna Polańska</P>
      <P>tel.: 607-638-688</P>
      <P>E-mail:</P>
      <P marginTop={0}>wroclawski.chor.zenski@gmail.com</P>
    </div>
  )
}
