import { ThemeContext } from "@emotion/react"
import { Provider as StoreProvider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { Footer } from "./components/domain/Footer"
import { NavBar } from "./components/domain/navbar/NavBar"
import { TopBar } from "./components/domain/topbar/TopBar"
import { FlashBar } from "./components/flash/FlashBar"
import { InjectorContext } from "./components/injector/InjectorContext"
import { Container } from "./components/pages/Container"
import { RootRoutes } from "./components/routes/RootRoutes"
import { defaultTheme } from "./modules/design/defaultTheme"
import { Injector } from "./modules/di/Injector"

type Props = {
  injector: Injector
}

export const App: React.FC<Props> = ({ injector }) => (
  <InjectorContext.Provider value={injector}>
    <BrowserRouter window={injector.win}>
      <StoreProvider store={injector.store}>
        <ThemeContext.Provider value={defaultTheme}>
          <TopBar />
          <NavBar />
          <FlashBar />
          <Container>
            <RootRoutes />
          </Container>
          <Footer />
        </ThemeContext.Provider>
      </StoreProvider>
    </BrowserRouter>
  </InjectorContext.Provider>
)
