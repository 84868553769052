/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"
import { useTheme } from "../../hooks/useTheme"
import { HProps } from "./HProps"

export const H3: React.FC<PropsWithChildren<HProps>> = ({
  alignSelf,
  children,
  color,
  letterSpacing,
  marginBottom,
  marginTop,
  strong = true,
  textAlign
}) => {
  const { colors, fonts } = useTheme()

  return (
    <h3
      css={{
        alignSelf,
        color: color || colors.primary[300],
        fontFamily: fonts.lato.name,
        fontWeight: strong ? fonts.lato.weight["700"] : fonts.lato.weight["400"],
        letterSpacing,
        lineHeight: "30px",
        margin: "0 0 8px 0",
        marginBottom,
        marginTop,
        textAlign
      }}
    >
      {children}
    </h3>
  )
}
