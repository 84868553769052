export const isopranos = {
  agataLuboinska: {
    pl: "Farmaceutka, która lubi wypełniać sobie wolny czas artystycznymi zajęciami i\xa0spotkaniami z\xa0ludźmi. Wrocławski Chór Żeński to do tego idealna przestrzeń.",
    en: ""
  },
  aleksandraWozniak: {
    pl: "Śpiewam w\xa0zasadzie od najmłodszych lat. W\xa0pierwszych klasach podstawówki dostawałam do dzienniczka uwagi za śpiewanie na lekcji (podczas rysowania szlaczków). I\xa0taka potrzeba została mi do dziś. Muzyka potrafi wzruszać mnie do łez, porywać, inspirować. Jestem gorącą miłośniczką i\xa0orędowniczką śpiewu w\xa0języku niemieckim (z\xa0racji uprawianego zawodu). Po latach śpiewania w\xa0chórach mieszanych, w\xa0Wrocławskim Chórze Żeńskim doceniam to, jak wspaniałą wspólnotę tworzą ze sobą kobiety. Jesteśmy dla siebie wspierające, serdeczne i\xa0empatyczne. Przecudowne grono!",
    en: ""
  },
  annaAleksandrowicz: {
    pl: "Śpiewam w\xa0chórze od dziecka i\xa0mam nadzieję w\xa0jego towarzystwie się zestarzeć.",
    en: ""
  },
  annaBialek: {
    pl: "Uczę, śpiewam, dyryguję. Wrocławski Chór Żeński to dla mnie powrót do korzeni i\xa0możliwość realizowania się jako chórzystka!",
    en: ""
  },
  annaMarcinek: {
    pl: "Architektka specjalizująca się w\xa0projektowaniu obiektów przemysłowych i\xa0biurowych. Na co dzień spełniona żona i\xa0mama. Chór traktuje jako swoje największe hobby, gdzie łapię oddech, realizuje się muzycznie i\xa0towarzysko.",
    en: ""
  },
  dominikaUrban: {
    pl: "Z\xa0zawodu - analityczka danych, z\xa0wykształcenia - matematyczka i\xa0skrzypaczka, z\xa0zamiłowania - chórzystka - nieprzerwanie od 18 lat. Uwielbia bajki, podróże, twórczość Tolkiena i\xa0spotkania z\xa0przyjaciółmi.",
    en: ""
  },
  kamilaMadej: {
    pl: "Farmaceutka, która w\xa0śpiewaniu w\xa0chórze odnalazła lekarstwo na zły humor. Uwielbia taniec towarzyski i\xa0gotowanie dla przyjaciół.",
    en: ""
  },
  nataliaMutor: {
    pl: "Jestem tkaczką. W\xa0mojej pracowni tworzę szale, chusty do noszenia dzieci, dywany, kilimy i\xa0inne tkaniny, w\xa0których utrwalam to, co dla nas najcenniejsze. Przez wiele lat pracowałam jako menedżerka kultury. Wolny czas spędzam z\xa0rodziną na torze speedrowerowym Szarży Wrocław. Wrocławski Chór Żeński kocham za przestrzeń, którą daje każdej kobiecie. Różne i\xa0podobne, zjednoczone śpiewem.",
    en: ""
  },
  olenaDziurdz: {
    pl: "Na co dzień jest analitykiem biznesowym i\xa0testerem stron internetowych. Uwielbia pracę zespołową, tworzyć nowe rzeczy i\xa0dzielić się nimi z\xa0innymi. Wrocławski Chór Żeński jest miejscem, gdzie wspólnie z\xa0innymi chórzystkami może tworzyć muzykę i\xa0dzielić się nią z\xa0innymi. Dodatkowo jest miłośniczką nart i\xa0książek.",
    en: ""
  }
}
