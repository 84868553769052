import { put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../action/PayloadAction"
import { Injector } from "../../di/Injector"
import { propagateUnknownError } from "../../error/actions"
import { clearFlash, setFlash } from "../../flash/actions"
import { BlackBoxGenerator } from "../../saga/types"
import { SendContactEmailPayload, SEND_CONTACT_EMAIL } from "../actions"

export function* watchSendResultByEmail(injector: Injector): BlackBoxGenerator {
  yield takeLatest(SEND_CONTACT_EMAIL, function* (action: PayloadAction<SendContactEmailPayload>): BlackBoxGenerator {
    yield put(clearFlash())
    const {
      data,
      formHelpers: { setSubmitting, resetForm }
    } = action.payload
    const service = injector.email.service

    try {
      yield service.sendContactEmail(data)
      setSubmitting(false)
      resetForm()
      yield put(
        setFlash({
          intent: "Success",
          message: "Twoja wiadomość została wysłana"
        })
      )
    } catch (error) {
      setSubmitting(false)
      yield put(propagateUnknownError(error))
    }
  })
}
