import { Theme } from "./Theme"

export const defaultTheme: Theme = {
  colors: {
    black: "#000000",
    danger: {
      300: "#E00030"
    },
    neutral: {
      300: "#ebecec"
    },
    primary: {
      100: "#e15594",
      300: "#d40c66",
      500: "#aa0a52"
    },
    success: {
      300: "#2B7E00"
    },
    white: "#ffffff"
  },
  fonts: {
    lato: {
      name: "Lato, sans-serif",
      weight: {
        300: 300,
        400: 400,
        700: 700
      }
    }
  },
  gaps: {
    1: 1,
    2: 2,
    3: 4,
    4: 8,
    5: 16,
    6: 32,
    7: 64
  }
}
