/** @jsxImportSource @emotion/react */
import { useCallback, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FlashData } from "../../modules/flash/FlashData"
import { clearFlash } from "../../modules/flash/actions"
import { MainState } from "../../modules/state/MainState"
import { FadeInOut } from "../animation/FadeInOut"
import { ScrollTo } from "../animation/ScrollTo"
import { useTheme } from "../design/hooks/useTheme"
import { Alert } from "../design/system/alert/Alert"
import { AlertXClose } from "../design/system/alert/AlertXClose"

const animationDuration = 500
const mapState = (state: MainState): FlashData | null => state.flash

export const Flash: React.FC = () => {
  const { gaps } = useTheme()
  const state = useSelector(mapState, shallowEqual)
  const [hiding, setHiding] = useState<boolean>(false)
  const dispatch = useDispatch()
  const onClick = useCallback(() => {
    setHiding(true)
    setTimeout(() => {
      dispatch(clearFlash())
      setHiding(false)
    }, animationDuration)
  }, [dispatch, setHiding])

  return (
    <FadeInOut visible={state != null && !hiding} duration={animationDuration}>
      {state != null ? (
        <ScrollTo visible={true}>
          <Alert intent={state.intent}>
            <div
              css={{
                display: "grid",
                columnGap: gaps[4],
                gridTemplateColumns: "auto auto",
                justifyContent: "space-between"
              }}
            >
              <div>{state.message}</div>
              <AlertXClose onClick={onClick}></AlertXClose>
            </div>
          </Alert>
        </ScrollTo>
      ) : null}
    </FadeInOut>
  )
}
