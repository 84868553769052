/** @jsxImportSource @emotion/react */
import group from "../../../../images/group/group.jpg"
import { useScreen } from "../../../screen/hooks/useScreen"

export const LandingGraphic: React.FC = () => {
  const { window } = useScreen()
  const isForSmallScreen = window.width < 650
  return (
    <img
      alt="Choir members"
      css={{ objectFit: "cover", borderRadius: 8, width: "100%", maxHeight: isForSmallScreen ? 225 : 360 }}
      src={group}
    />
  )
}
