/** @jsxImportSource @emotion/react */
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { useScreen } from "../../../screen/hooks/useScreen"
import { Icon } from "./Icon"

export const IconGroup: React.FC = () => {
  const { window } = useScreen()

  return (
    <div
      css={{
        alignSelf: "center",
        display: "flex",
        paddingLeft: window.width < 1000 ? 5 : 60,
        paddingRight: 10,
        width: window.width < 1000 ? 100 : 130,
        justifyContent: "space-between",
        a: {
          transform: "rotate(0deg) scale(1)",
          transition: "transform .2s ease-in",
          "&:hover": {
            transform: "rotate(3deg) scale(1.2)"
          }
        }
      }}
    >
      <a href="mailto:wroclawski.chor.zenski@gmail.com">
        <Icon icon={faEnvelope} />
      </a>
      <a href="https://www.facebook.com/profile.php?id=100070164977983" target="_blank" rel="noreferrer">
        <Icon icon={faFacebookF} />
      </a>
    </div>
  )
}
