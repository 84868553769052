/** @jsxImportSource @emotion/react */
import { useTheme } from "../design/hooks/useTheme"

export const Footer: React.FC = () => {
  const { colors, fonts } = useTheme()
  return (
    <footer
      css={{
        backgroundColor: colors.primary[300],
        color: colors.white,
        fontFamily: fonts.lato.name,
        fontWeight: fonts.lato.weight["400"],
        marginTop: 21,
        textAlign: "center",
        width: "100%"
      }}
    >
      Made with ♫ and ❤️ by Wrocławski Chór Żeński
    </footer>
  )
}
