import { Injector } from "../../modules/di/Injector"
import { EventsService } from "../../modules/domain/events/EventsService"
import { EmailService } from "../../modules/email/EmailService"
import { GraphQLClient } from "../../modules/graphql/GraphQLClient"
import { config } from "../config"
import { logger } from "../logger"
import { routes } from "../routes"
import { sagaMiddleware, store } from "../store"

const graphqlClient = new GraphQLClient(config.graphql.path)

export const createMainInjector = (): Injector => ({
  config,
  doc: document,
  domain: {
    events: {
      service: new EventsService(graphqlClient)
    }
  },
  email: {
    service: new EmailService(config.emailjs.publicKey, config.emailjs.templates, config.emailjs.serviceId)
  },
  graphql: {
    client: graphqlClient
  },
  logger,
  routes,
  sagaMiddleware,
  store,
  win: window
})
