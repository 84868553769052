import { Injector } from "../../di/Injector"
import { noop } from "../../saga/noop"
import { BlackBoxGenerator } from "../../saga/types"
import { setIsVisible } from "../actions"

export function* watchVisibilityChange({ doc, store }: Injector): BlackBoxGenerator {
  doc.addEventListener("visibilitychange", () => {
    store.dispatch(setIsVisible(!doc.hidden))
  })

  yield* noop()
}
