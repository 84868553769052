/** @jsxImportSource @emotion/react */

import { PropsWithChildren } from "react"
import { ModalBackDrop } from "./ModalBackDrop"
import { ModalBox } from "./ModalBox"
import { ModalCloseBar } from "./ModalCloseBar"
import { ModalElement } from "./ModalElement"

type Props = {
  maxWidth?: number
  onClose: () => void
}

export const CloseableModal: React.FC<PropsWithChildren<Props>> = ({ children, maxWidth, onClose }) => {
  return (
    <ModalElement>
      <ModalBackDrop onDirectClick={onClose}>
        <ModalBox maxWidth={maxWidth}>
          <ModalCloseBar onClose={onClose} />
          {children}
        </ModalBox>
      </ModalBackDrop>
    </ModalElement>
  )
}
