import { Theme } from "../design/Theme"
import { Intent } from "./Intent"

export const getForegroundColorForIntent = (intent: Intent, useAltShade: boolean, colors: Theme["colors"]): string => {
  const shade = 300

  switch (intent) {
    case "Primary":
      return useAltShade ? colors.primary[shade] : colors.white
    case "Danger":
      return colors.white
    case "Success":
      return colors.white
    default:
      return "inherit"
  }
}
