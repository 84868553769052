/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"
import { useScreen } from "../../../screen/hooks/useScreen"
import { useTheme } from "../../hooks/useTheme"
import { HProps } from "./HProps"

export const H2: React.FC<PropsWithChildren<HProps>> = ({
  alignSelf,
  children,
  color,
  letterSpacing,
  marginBottom,
  marginTop,
  strong = true,
  textAlign
}) => {
  const { colors, fonts } = useTheme()
  const { window } = useScreen()

  return (
    <h2
      css={{
        alignSelf,
        color: color || colors.primary[300],
        fontFamily: fonts.lato.name,
        fontWeight: strong ? fonts.lato.weight["700"] : fonts.lato.weight["400"],
        fontSize: window.width < 1120 ? 21 : 24,
        letterSpacing,
        lineHeight: "40px",
        margin: "0 0 8px 0",
        marginBottom,
        marginTop,
        textAlign
      }}
    >
      {children}
    </h2>
  )
}
