import { put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../../action/PayloadAction"
import { Injector } from "../../../di/Injector"
import { propagateUnknownError } from "../../../error/actions"
import { clearFlash } from "../../../flash/actions"
import { BlackBoxGenerator } from "../../../saga/types"
import { GetEventsPayload, GET_EVENTS, setEvents } from "../actions"

export function* watchGetEvents(injector: Injector): BlackBoxGenerator {
  yield takeLatest(GET_EVENTS, function* (action: PayloadAction<GetEventsPayload>): BlackBoxGenerator {
    yield put(clearFlash())

    try {
      const service = injector.domain.events.service
      const pagination = (yield service.getEventsPaginated(action.payload.first, action.payload.skip)) as Awaited<
        ReturnType<typeof service.getEventsPaginated>
      >
      yield put(setEvents({ hasNextPage: pagination.hasNextPage, events: action.payload.currentState.concat(pagination.events) }))
    } catch (error) {
      yield put(propagateUnknownError(error))
    }
  })
}
