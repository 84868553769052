import { Routes, Route } from "react-router-dom"
import { ChoirPage } from "../pages/choir/ChoirPage"
import { ConductorPage } from "../pages/conductor/ConductorPage"
import { ContactPage } from "../pages/contact/ContactPage"
import { Error404 } from "../pages/errors/Error404"
import { EventsPage } from "../pages/events/EventsPage"
import { EventDetails } from "../pages/events/details/EventDetails"
import { MainPage } from "../pages/main/MainPage"

export const RootRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<MainPage />} />
    <Route path="/chor" element={<ChoirPage />} />
    <Route path="/dyrygentka" element={<ConductorPage />} />
    <Route path="/wydarzenia">
      <Route path="" element={<EventsPage />} />
      <Route path="szczegoly" element={<EventDetails />} />
    </Route>
    <Route path="/kontakt" element={<ContactPage />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
)
