/** @jsxImportSource @emotion/react */
import { FormikConfig, useFormik } from "formik"
import { useMemo } from "react"
import * as Yup from "yup"
import { requiredString } from "../../../modules/form/requiredString"
import { useTheme } from "../../design/hooks/useTheme"
import { P } from "../../design/system/P"
import { Button } from "../../design/system/button/Button"
import { FormFieldError } from "../../design/system/form/FormFieldError"
import { useScreen } from "../../screen/hooks/useScreen"

type EmailFormValues = {
  senderEmail: string
  senderName: string
  message: string
}

type Props = {
  onSubmit: FormikConfig<EmailFormValues>["onSubmit"]
}

const initialValues: EmailFormValues = {
  senderEmail: "",
  senderName: "",
  message: ""
}

export const ContactPageEmailForm: React.FC<Props> = ({ onSubmit }) => {
  const { colors, fonts } = useTheme()
  const { window } = useScreen()
  const validationSchema: Yup.SchemaOf<EmailFormValues> = useMemo(
    () =>
      Yup.object().shape({
        senderEmail: requiredString("To pole jest wymagane").email("Podaj poprawny adres email"),
        senderName: requiredString("To pole jest wymagane"),
        message: requiredString("To pole jest wymagane")
      }),
    []
  )

  const formik = useFormik<EmailFormValues>({
    validationSchema,
    initialValues,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      css={{
        width: window.width > 460 ? 460 : "100%",
        marginBottom: 50
      }}
    >
      <div
        css={{
          backgroundColor: colors.neutral[300],
          height: 450,
          padding: "30px",
          position: "relative",
          width: "100%",
          "> input, > textarea": {
            border: "none",
            fontFamily: fonts.lato.name,
            fontSize: 16,
            padding: "5px 10px",
            width: "100%"
          },
          "> input": {
            height: 35
          },
          "> textarea": {
            height: "45%",
            resize: "none"
          }
        }}
      >
        <P>Wyślij wiadomość</P>
        <input name="senderName" onChange={formik.handleChange} placeholder="imię" type={"text"} value={formik.values.senderName} />
        <FormFieldError message={formik.errors.senderName} />
        <input name="senderEmail" onChange={formik.handleChange} placeholder="email" type={"email"} value={formik.values.senderEmail} />
        <FormFieldError message={formik.errors.senderEmail} />
        <textarea name="message" onChange={formik.handleChange} placeholder="treść wiadomości" value={formik.values.message} />
        <FormFieldError message={formik.errors.message} />
        <Button disabled={formik.isSubmitting} float="right" intent="Primary" type="submit">
          WYŚLIJ
        </Button>
      </div>
    </form>
  )
}
