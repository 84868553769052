/** @jsxImportSource @emotion/react */
import { NavLink } from "react-router-dom"
import { useTheme } from "../../design/hooks/useTheme"
import { useRoutes } from "../../routes/hooks/useRoutes"
import { useScreen } from "../../screen/hooks/useScreen"
import { NavElement } from "./NavElement"

export const NavBar: React.FC = () => {
  const { colors } = useTheme()
  const { window } = useScreen()
  const routes = useRoutes()
  const isForSmallScreen = window.width < 750

  return (
    <nav>
      <ul
        css={{
          display: "flex",
          flexDirection: isForSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          listStyle: "none",
          margin: "auto",
          padding: "10px 10px 20px 10px",
          maxWidth: 1100,
          a: {
            transition: "color .2s ease-in",
            position: "relative",
            "&.active": {
              color: colors.primary[300],
              "&::after": {
                width: "100%",
                left: 0
              }
            },
            "&::after": {
              bottom: 0,
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              height: 2,
              left: "50%",
              position: "absolute",
              background: colors.primary[300],
              transition: "width 0.3s ease 0s, left 0.3s ease 0s",
              width: 0,
              "&:hover": {
                width: "100%",
                left: 0
              }
            },
            "&:hover": {
              color: colors.primary[300],
              "&::after": {
                width: "100%",
                left: 0
              }
            }
          }
        }}
      >
        <NavLink to={routes.choir()}>
          <NavElement>ZESPÓŁ</NavElement>
        </NavLink>
        <NavLink to={routes.conductor()}>
          <NavElement>DYRYGENTKA</NavElement>
        </NavLink>
        <NavElement>
          <a href="https://www.youtube.com/channel/UCPxSTvuqNtMCgn6-Gg1TtCA" target="_blank" rel="noreferrer">
            POSŁUCHAJ
          </a>
        </NavElement>
        <NavLink to={routes.events.index()}>
          <NavElement>WYDARZENIA</NavElement>
        </NavLink>
        <NavLink to={routes.contact()}>
          <NavElement>KONTAKT</NavElement>
        </NavLink>
      </ul>
    </nav>
  )
}
