/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom"
import { useTheme } from "../../../../design/hooks/useTheme"
import { H1 } from "../../../../design/system/headers/H1"
import { H2 } from "../../../../design/system/headers/H2"
import { useScreen } from "../../../../screen/hooks/useScreen"

export type MainAboutUsCardProps = {
  image: string
  route: string
  subtitle: string
  title: string
}

export const MainAboutUsCard: React.FC<MainAboutUsCardProps> = ({ image, subtitle, title, route }) => {
  const { colors } = useTheme()
  const { window } = useScreen()
  const isForSmallScreen = window.width < 660

  return (
    <Link
      css={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        justifyContent: "flex-end",
        textShadow: ".5px 0px 0 #000, 0px 0px 0 #000, 0px 0px 0 #000, .5px 1px 0 #000",
        height: isForSmallScreen ? 330 : 450,
        width: isForSmallScreen ? 345 : 430,
        transition: "box-shadow 200ms ease-in-out",
        "&:hover": {
          boxShadow: "0px 0px 24px -7px rgba(0, 0, 0, 1)"
        }
      }}
      to={route}
    >
      <H1 color={colors.white} textAlign="center">
        {title}
      </H1>
      <H2 color={colors.white} strong={false} textAlign="center">
        {subtitle}
      </H2>
    </Link>
  )
}
