import { all, Effect } from "@redux-saga/core/effects"
import { Saga } from "redux-saga"
import { Injector } from "../modules/di/Injector"
import { domain } from "../modules/domain/sagas"
import { email } from "../modules/email/sagas"
import { error } from "../modules/error/sagas"
import { screen } from "../modules/screen/sagas"

export const sagas: (injector: Injector) => Saga = (injector) =>
  function* sagas(): Generator<Effect> {
    yield all([domain(injector), email(injector), error(injector), screen(injector)])
  }
