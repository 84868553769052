import alicjaTrela from "../../../../images/voices/lowres/iisopranos/AlicjaTrela.jpg"
import annaBudekKorczagin from "../../../../images/voices/lowres/iisopranos/AnnaBudekKorczagin.jpg"
import annaSeidelMydlowska from "../../../../images/voices/lowres/iisopranos/AnnaSeidelMydlowska.jpg"
import joannaKobylinska from "../../../../images/voices/lowres/iisopranos/JoannaKobylinska.jpg"
import martynaPieniazek from "../../../../images/voices/lowres/iisopranos/MartynaPieniazek.jpg"
import paulaSobok from "../../../../images/voices/lowres/iisopranos/PaulaSobok.jpg"
import paulinaLopatka from "../../../../images/voices/lowres/iisopranos/PaulinaLopatka.jpg"
import sylwiaWrzeszcz from "../../../../images/voices/lowres/iisopranos/SylwiaWrzeszcz.jpg"
import { iisopranos } from "./descriptions/iisopranos"
import { Voice } from "./parts/Voice"
import { VoiceSection } from "./parts/VoiceSection"

export const IISopranos: React.FC = () => {
  return (
    <VoiceSection name="II SOPRANY">
      <Voice
        description={iisopranos.alicjaTrela.pl}
        highResPhoto="/iisopranos/AlicjaTrela"
        firstName="Alicja"
        lastName="Trela"
        photo={alicjaTrela}
      />
      <Voice
        description={iisopranos.annaBudekKorczagin.pl}
        highResPhoto="/iisopranos/AnnaBudekKorczagin"
        firstName="Anna"
        lastName="Budek-Korczagin"
        photo={annaBudekKorczagin}
      />
      <Voice
        description={iisopranos.annaSeidelMydlowska.pl}
        highResPhoto="/iisopranos/AnnaSeidelMydlowska"
        firstName="Anna"
        lastName="Seidel-Mydłowska"
        photo={annaSeidelMydlowska}
      />
      <Voice
        description={iisopranos.joannaKobylinska.pl}
        highResPhoto="/iisopranos/JoannaKobylinska"
        firstName="Joanna"
        lastName="Kobylińska"
        photo={joannaKobylinska}
      />
      <Voice
        description={iisopranos.martynaPieniazek.pl}
        highResPhoto="/iisopranos/MartynaPieniazek"
        firstName="Martyna"
        lastName="Pieniążek"
        photo={martynaPieniazek}
      />
      <Voice
        description={iisopranos.paulaSobok.pl}
        highResPhoto="/iisopranos/PaulaSobok"
        firstName="Paula"
        lastName="Sobok"
        photo={paulaSobok}
      />
      <Voice
        description={iisopranos.paulinaLopatka.pl}
        highResPhoto="/iisopranos/PaulinaLopatka"
        firstName="Paulina"
        lastName="Łopatka"
        photo={paulinaLopatka}
      />
      <Voice
        description={iisopranos.sylwiaWrzeszcz.pl}
        highResPhoto="/iisopranos/SylwiaWrzeszcz"
        firstName="Sylwia"
        lastName="Wrzeszcz"
        photo={sylwiaWrzeszcz}
      />
    </VoiceSection>
  )
}
