/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"

type Props = {
  opacityOnOut?: number
  visible: boolean
  duration: number
}

export const FadeInOut: React.FC<PropsWithChildren<Props>> = ({ visible, children, duration, opacityOnOut }) => (
  <div
    css={{
      opacity: visible ? 1 : opacityOnOut ?? 0,
      transition: `opacity ${duration}ms ease-in-out`
    }}
  >
    {children}
  </div>
)
