import { put, takeLatest } from "redux-saga/effects"
import { PayloadAction } from "../../../action/PayloadAction"
import { Injector } from "../../../di/Injector"
import { propagateUnknownError } from "../../../error/actions"
import { clearFlash } from "../../../flash/actions"
import { BlackBoxGenerator } from "../../../saga/types"
import { GET_EVENT_DETAILS, setEventDetails } from "../actions"

export function* watchGetEventDetails(injector: Injector): BlackBoxGenerator {
  yield takeLatest(GET_EVENT_DETAILS, function* (action: PayloadAction<string>): BlackBoxGenerator {
    yield put(clearFlash())

    try {
      const service = injector.domain.events.service
      const eventDetails = (yield service.getEventDetails(action.payload)) as Awaited<ReturnType<typeof service.getEventDetails>>
      yield put(setEventDetails(eventDetails))
    } catch (error) {
      yield put(propagateUnknownError(error))
    }
  })
}
