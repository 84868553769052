/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"
import { useTheme } from "../../../../design/hooks/useTheme"
import { H1 } from "../../../../design/system/headers/H1"

type MainSectionProps = {
  sectionTitle: string
}

export const MainSection: React.FC<PropsWithChildren<MainSectionProps>> = ({ children, sectionTitle }) => {
  const { colors } = useTheme()

  return (
    <section css={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between", paddingTop: 50 }}>
      <H1 color={colors.primary[300]} textAlign="left">
        {sectionTitle}
      </H1>
      {children}
    </section>
  )
}
