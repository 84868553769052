import { GraphQLClient, QueryResult } from "../../graphql/GraphQLClient"
import { graphql } from "../../graphql/graphql"
import { EventDefinition } from "./EventDefinition"
import { EventDetails } from "./EventDetails"
import { EventDetailsQuery, EventsQuery } from "./EventsService.generated"
import { convertEventDefinition } from "./graphql/converters/convertEventDefinition"
import { convertEventDetails } from "./graphql/converters/convertEventDetails"

export class EventsService {
  constructor(private client: GraphQLClient) {}

  async getEventsPaginated(first: number, skip: number): Promise<{ hasNextPage: boolean; events: EventDefinition[] }> {
    const result: QueryResult<EventsQuery> = await this.client.query({
      fetchPolicy: "cache-first",
      query: graphql`
        query Events($locales: [Locale!]!, $first: Int!, $skip: Int!) {
          wydarzeniasConnection(locales: $locales, orderBy: dataWydarzenia_DESC, first: $first, skip: $skip) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                tytul
                urlPlakatu
                locale
                dataWydarzenia
              }
            }
          }
        }
      `,
      variables: {
        locales: ["pl"],
        first,
        skip
      }
    })

    return {
      hasNextPage: result.data.wydarzeniasConnection.pageInfo.hasNextPage,
      events: result.data.wydarzeniasConnection.edges.map(convertEventDefinition)
    }
  }

  async getEventDetails(id: string): Promise<EventDetails> {
    const result: QueryResult<EventDetailsQuery> = await this.client.query({
      fetchPolicy: "cache-first",
      query: graphql`
        query EventDetails($id: ID!, $locales: [Locale!]!) {
          wydarzenia(where: { id: $id }, locales: $locales) {
            id
            tytul
            urlPlakatu
            dataWydarzenia
            opisWydarzenia {
              html
            }
          }
        }
      `,
      variables: {
        locales: ["pl"],
        id
      }
    })

    if (result.data.wydarzenia != null) {
      return convertEventDetails(result.data.wydarzenia)
    }

    throw new Error(`Cannot obtain event details with id: ${id}`)
  }
}
