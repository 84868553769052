import { EventDefinition } from "../../EventDefinition"
import { EventsQuery } from "../../EventsService.generated"

export const convertEventDefinition = ({
  node: { id, tytul, urlPlakatu, dataWydarzenia }
}: EventsQuery["wydarzeniasConnection"]["edges"][0]): EventDefinition => {
  return {
    id,
    title: tytul,
    poster: urlPlakatu,
    date: dataWydarzenia
  }
}
