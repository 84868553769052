/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../design/hooks/useTheme"
import { P } from "../../../design/system/P"
import { H1 } from "../../../design/system/headers/H1"

export const ChoirAboutUs: React.FC = () => {
  const { colors } = useTheme()

  return (
    <section css={{ paddingTop: 20, width: "100%" }}>
      <H1 color={colors.primary[300]} textAlign="left">
        OTO MY!
      </H1>
      <div>
        <P justify>
          16 czerwca 2021 roku spotkałyśmy się po raz pierwszy. Każda z nas zdobyła do tej pory pewne doświadczenie w&nbsp;śpiewie
          zespołowym. Łącząc siły, pełne zapału i&nbsp;chęci ruszyłyśmy do tworzenia nowego artystycznego bytu. Przyciąga nas do siebie
          wizja wspólnego śpiewania, koncertowania i&nbsp;podejmowania nowych muzycznych wyzwań. Atmosfera panująca na próbach, tempo pracy
          nad utworami oraz realizacja ambitnych planów utwierdzają nas w tym, że warto wspólnie tworzyć coś wyjątkowego. Na co dzień
          jesteśmy psycholożkami, fizjoterapeutkami, lekarkami, architektkami, farmaceutkami, dentystkami, filolożkami, logopedkami,
          programistkami, analityczkami, nauczycielkami i&nbsp;artystkami różnych specjalności, liderkami w swoich miejscach pracy. Panny,
          panie, matki, żony, koleżanki, ambitne, zdolne, wrażliwe. Mimo, że każda z&nbsp;nas jest inna, znalazłyśmy przestrzeń, w której
          funkcjonujemy, myślimy i&nbsp;czujemy podobnie. Udało się to w&nbsp;dużej części dzięki pokrewnej duszy, która nas prowadzi.
          Izabela Polakowska-Rybska to jedna z nas, dlatego w trakcie wspólnych spotkań czujemy się wyjątkowo swobodnie w relacji dyrygent -
          zespół, a&nbsp;nasz potencjał kreatywny rozwija się z&nbsp;każdą wspólnie odbytą próbą. Co nas łączy? HARMONIA i&nbsp;muzyka
          poprzez którą chcemy wyrażać siebie i&nbsp;tworzyć rzeczy nienazwane. <br />
          <br />
        </P>
        <P>
          Wrocławski Chór Żeński jest laureatem: <br /> <br />
          VII Ogólnopolskiego Festiwalu Pieśni Religijnej „Pater Noster”, Strzepcz 2023 r.
          <ul>
            <li>Grand Prix Festiwalu</li>
            <li>I Miejsce w kategorii chórów świeckich</li>
            <li>Wyróżnienie za najlepiej wykonany utwór w języku kaszubskim - Anna Rocławska-Musiałczyk „Chwała Tobie Królu Wieków”</li>
            <li>
              Nagroda Biura Pielgrzymkowego „Kaszub Travel” z Kościerzyny dla Najlepszego Dyrygenta Festiwalu - Izabeli
              Polakowskiej-Rybskiej
            </li>
          </ul>
          34. Międzynarodowego Festiwalu Muzyki Religijnej im. ks. Stanisława Ormińskiego w Rumi, Rumia 2022 r.
          <ul>
            <li>Złoty Dyplom</li>
            <li>I miejsce</li>
          </ul>
          Ogólnopolskiego Konkursu Chóralnego w ramach Ogólnopolskiego Forum Chóralistyki „Wrocław Miastem Muzyki”, Wrocław 2021 r.
          <ul>
            <li>Złoty Dyplom</li>
          </ul>
        </P>
      </div>
    </section>
  )
}
