import { emptyAction, payloadAction } from "../../action/creators"
import { EventDefinition } from "./EventDefinition"
import { EventDetails } from "./EventDetails"

const prefix = "EVENTS"

export type GetEventsPayload = {
  currentState: EventDefinition[]
  first: number
  skip: number
}

export const GET_EVENTS = `${prefix}/GET`
export const getEvents = payloadAction<GetEventsPayload>(GET_EVENTS)

export const SET_EVENTS = `${prefix}/SET`
export const setEvents = payloadAction<{ hasNextPage: boolean; events: EventDefinition[] }>(SET_EVENTS)

export const GET_EVENT_DETAILS = `${prefix}/DETAILS/GET`
export const getEventDetails = payloadAction<string>(GET_EVENT_DETAILS)

export const SET_EVENT_DETAILS = `${prefix}/DETAILS/SET`
export const setEventDetails = payloadAction<EventDetails>(SET_EVENT_DETAILS)

export const CLEAR_EVENT_DETAILS = `${prefix}/DETAILS/CLEAR`
export const clearEventDetails = emptyAction(CLEAR_EVENT_DETAILS)
