export const ialtos = {
  agataSkibinska: {
    pl: "Na co dzień pracuję z danymi jako programistka. Uwielbiam spędzać poniedziałkowe popołudnia na próbie chóru w gronie inspirujących kobiet i\xa0wspólnym śpiewie. To zastrzyk energii na cały tydzień.",
    en: ""
  },
  agnieszkaBereza: {
    pl: "Z zawodu lekarz psychiatra, a\xa0wolnych chwilach alt pierwszy w Wrocławskim Chórze Żeńskim i\xa0wokalistka rockowego zespołu. Poza muzyką uwielbiam filmy, podróże, jazdę na nartach oraz poznawanie nowych ludzi i\xa0kultur.",
    en: ""
  },
  aleksandraMroczko: {
    pl: "Na co dzień realizuje się jako mama i\xa0psycholożka. Inspirują i\xa0ciekawią ją ludzie. Czas wolny chętnie spędza aktywnie wśród natury, na rowerze, w górach lub w zaciszu domowym z\xa0książką czy pędzlem. Miłość do śpiewu chóralnego towarzyszy jej od kilkunastu lat a\xa0cotygodniowe próby dają upust potrzebom współtworzenia, muzykowania, wyrażania siebie i\xa0przebywania w\xa0kojącym kobiecym gronie.",
    en: ""
  },
  dagmaraGrenik: {
    pl: "Śpiewający mezzosopranem lekarz rodzinny. Śpiew chóralny jest dla mnie wytchnieniem w codziennym zabieganiu i\xa0przestrzenią do bycia i\xa0tworzenia RAZEM! Uwielbiam też podróże na koniec świata.",
    en: ""
  },
  ewaLukasikKrol: {
    pl: "Chór jest dla mnie miejscem, w\xa0którym mogę odkrywać swoją wrażliwość i\xa0spotkać się z\xa0wrażliwością innych kobiet. Śpiew dostarcza mi dużych emocji, daje spełnienie i\xa0budzi miękkość, zapewnia energię, by realizować się na innych polach: jestem mamą trójki dzieci,\xa0a zawodowo zajmuję się kontrolingiem finansowym.",
    en: ""
  },
  ewaSkorska: {
    pl: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque quas aut nemo ut odio, vitae ipsam magni veniam voluptatem itaque. Eius sint alias dignissimos illum nesciunt autem pariatur vitae i musicale.",
    en: ""
  },
  katarzynaSzeloch: {
    pl: "Śpiewa w\xa0chórach i\xa0zespołach nieprzerwanie od 8 r.ż., kocha harmonię i\xa0tworzenie muzyki, gra na fortepianie, lubuje się w jazzie i\xa0bossa novie, do tańca uwielbia salsę. Na co dzień menadżer programów i\xa0projektów, żona i\xa0mama 2\xa0dzieci, opiekunka ogrodu, kota i\xa0psa.",
    en: ""
  },
  martaLaburda: {
    pl: "Z\xa0wykształcenia dziennikarka, obecnie specjalizująca się w\xa0branży Public Relations i\xa0Video Marketingu. W\xa0wolnych chwilach śpiewa. Czasem w\xa0pracy pod nosem, czasem głośno pod prysznicem, czasem celowo w\xa0aucie albo nieświadomie w\xa0sklepie, a\xa0w\xa0poniedziałki także na próbach chóru - zazwyczaj drugim lub pierwszym altem, ale potrafi też trochę wyżej.",
    en: ""
  },
  martaLemieszewska: {
    pl: "Muzyka jest częścią mojego życia od najmłodszych lat - zaczęło się od nauki gry na skrzypcach w\xa0szkole podstawowej. Po latach rozwoju zawodowego (biotechnologia, biologia medyczna) odkryłam nową pasję, jaką jest śpiewanie w\xachórze i\xa0chyba nie wyobrażam sobie już życia bez niego.",
    en: ""
  },
  sabinaKlodnicka: {
    pl: "Technolożka żywności, czuwa nad recepturami w\xa0piekarni. W wolnym czasie piecze torty i\xa0wszelkiego rodzaju desery. Kocha żeglować beztrosko po morzu ale też wspinać się w\xa0Alpach. Śpiewać kocha absolutnie od zawsze. Bycie częścią Wrocławskiego Chóru Żeńskiego jest okazją do wyrażenia siebie jeszcze bardziej.",
    en: ""
  }
}
