import adrianaIkoniak from "../../../../images/voices/lowres/iialtos/AdrianaIkoniak.jpg"
import aleksaMucha from "../../../../images/voices/lowres/iialtos/AleksaMucha.jpg"
import annaZarkadas from "../../../../images/voices/lowres/iialtos/AnnaZarkadas.jpg"
import dariaPisula from "../../../../images/voices/lowres/iialtos/DariaPisula.jpg"
import hannaKonieczna from "../../../../images/voices/lowres/iialtos/HannaKonieczna.jpg"
import izabelaGrad from "../../../../images/voices/lowres/iialtos/IzabelaGrad.jpg"
import joannaPolanska from "../../../../images/voices/lowres/iialtos/JoannaPolanska.jpg"
import katarzynaGemborys from "../../../../images/voices/lowres/iialtos/KatarzynaGemborys.jpg"
import katarzynaRadon from "../../../../images/voices/lowres/iialtos/KatarzynaRadon.jpg"
import { iialtos } from "./descriptions/iialtos"
import { Voice } from "./parts/Voice"
import { VoiceSection } from "./parts/VoiceSection"

export const IIAltos: React.FC = () => {
  return (
    <VoiceSection name="II ALTY">
      <Voice
        description={iialtos.adrianaIkoniak.pl}
        highResPhoto="/iialtos/AdrianaIkoniak"
        firstName="Adriana"
        lastName="Ikoniak"
        photo={adrianaIkoniak}
      />
      <Voice
        description={iialtos.aleksaMucha.pl}
        highResPhoto="/iialtos/AleksaMucha"
        firstName="Aleksa"
        lastName="Mucha"
        photo={aleksaMucha}
      />
      <Voice
        description={iialtos.annaZarkadas.pl}
        highResPhoto="/iialtos/AnnaZarkadas"
        firstName="Anna"
        lastName="Zarkadas"
        photo={annaZarkadas}
      />
      <Voice
        description={iialtos.dariaPisula.pl}
        highResPhoto="/iialtos/DariaPisula"
        firstName="Daria"
        lastName="Pisula"
        photo={dariaPisula}
      />
      <Voice
        description={iialtos.hannaKonieczna.pl}
        highResPhoto="/iialtos/HannaKonieczna"
        firstName="Hanna"
        lastName="Konieczna"
        photo={hannaKonieczna}
      />
      <Voice
        description={iialtos.izabelaGrad.pl}
        highResPhoto="/iialtos/IzabelaGrad"
        firstName="Izabela"
        lastName="Grad"
        photo={izabelaGrad}
      />
      <Voice
        description={iialtos.joannaPolanska.pl}
        highResPhoto="/iialtos/JoannaPolanska"
        firstName="Joanna"
        lastName="Polańska"
        photo={joannaPolanska}
      />
      <Voice
        description={iialtos.katarzynaGemborys.pl}
        highResPhoto="/iialtos/KatarzynaGemborys"
        firstName="Katarzyna"
        lastName="Gemborys"
        photo={katarzynaGemborys}
      />
      <Voice
        description={iialtos.katarzynaRadon.pl}
        highResPhoto="/iialtos/KatarzynaRadon"
        firstName="Katarzyna"
        lastName="Radoń"
        photo={katarzynaRadon}
      />
    </VoiceSection>
  )
}
