/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"
import { Intent } from "../../../../modules/intent/Intent"
import { getBackgroundColorForIntent } from "../../../../modules/intent/getBackgroundColorForIntent"
import { getForegroundColorForIntent } from "../../../../modules/intent/getForegroundColorForIntent"
import { useTheme } from "../../hooks/useTheme"

type Props = {
  intent: Intent
}

export const Alert: React.FC<PropsWithChildren<Props>> = ({ children, intent }) => {
  const { colors, fonts } = useTheme()

  return (
    <div
      css={{
        backgroundColor: getBackgroundColorForIntent(intent, false, false, colors),
        borderRadius: 8,
        color: getForegroundColorForIntent(intent, false, colors),
        fontFamily: fonts.lato.name,
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "28px",
        padding: "18px 24px"
      }}
    >
      {children}
    </div>
  )
}
