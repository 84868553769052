/** @jsxImportSource @emotion/react */

import { PropsWithChildren } from "react"

export const Container: React.FC<PropsWithChildren> = ({ children }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
      maxWidth: 950,
      padding: "0 10px",
      minHeight: "calc(100vh - 135px - 78px - 30px - 20px)",
      width: "100%"
    }}
  >
    {children}
  </div>
)
