/** @jsxImportSource @emotion/react */
import { Property } from "csstype"
import { PropsWithChildren } from "react"
import { useScreen } from "../../screen/hooks/useScreen"
import { useTheme } from "../hooks/useTheme"

type Props = {
  marginTop?: Property.MarginTop
  paddingTop?: Property.PaddingTop
  strong?: boolean
  justify?: boolean
}

export const P: React.FC<PropsWithChildren<Props>> = ({ children, marginTop, paddingTop, strong, justify }) => {
  const { colors, fonts } = useTheme()
  const { window } = useScreen()

  return (
    <p
      css={{
        color: colors.black,
        fontFamily: fonts.lato.name,
        fontSize: window.width < 1120 ? "15px" : "18px",
        fontWeight: strong ? fonts.lato.weight["700"] : fonts.lato.weight["400"],
        lineHeight: "28px",
        margin: 0,
        marginTop,
        paddingTop,
        textAlign: justify ? "justify" : "unset",
        "& + &": {
          marginTop: 28
        }
      }}
    >
      {children}
    </p>
  )
}
