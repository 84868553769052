import { Config } from "../config/Config"
import { environment } from "./environment"

const commonConfig = {
  emailjs: {
    publicKey: "vLsyWXnFyADkQoPYb",
    serviceId: "service_7gkw33h",
    templates: {
      contact: "template_ng93kxa"
    }
  },
  graphql: {
    path: "https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cla0y40id03gk01tb6d67gsyq/master"
  }
}

const development: Config = {
  environment: "development",
  ...commonConfig
}

const production: Config = {
  environment: "production",
  ...commonConfig
}

export const config: Config = (() => {
  switch (environment) {
    case "development":
      return development
    case "production":
      return production
    default:
      throw Error(`No config for the ${environment} environment`)
  }
})()
