import { Environment } from "../config/Environment"

export const environment: Environment = (() => {
  const env = process.env.REACT_APP_ENV as Environment
  switch (env) {
    case "development":
    case "production":
      return env
    default:
      throw Error(`Unsupported environment ${env}`)
  }
})()
