export const iialtos = {
  adrianaIkoniak: {
    pl: "Z wykształcenia polonistka, teatrolożka i\xa0logopedka. Miłość do muzyki zdradzała już jako kilkulatka, śpiewając po kątach przeboje Majki Jeżowskiej, natomiast w\xa0wieku lat kilkunastu zakochała się po uszy w\xa0śpiewie zespołowym. Do dziś miała przyjemność występować zarówno w\xa0szeregach chórów żeńskich jak i\xa0mieszanych, tych kameralnych jak i\xa0akademickich. Ma nadzieję, że śpiew będzie towarzyszył jej do ostatnich chwil - jak jej babci, która w\xa0siódmej dziesiątce swojego życia nadal śpiewała w\xa0chórze „Młode Muzy”.",
    en: ""
  },
  aleksaMucha: {
    pl: "Zawodowo lekarz dentysta. Miłośniczka stomatologii, zwierząt i\xa0lata. Drugą, po pracy, jej największą pasją jest muzyka - od klasycznej, po heavy metal. Śpiew w\xa0Wrocławskim Chórze Żeńskim pozwala jej spełniać marzenia o\xa0byciu artystką i\xa0obdarowywać ludzi uśmiechem - nie tylko w\xa0gabinecie.",
    en: ""
  },
  annaZarkadas: {
    pl: "Z\xa0wykształcenia kulturoznawca, zawodowo rekruter a\xa0prywatnie: trochę szalona mama, pasjonatka greckiej muzyki, rowerzystka i\xa0domowa artystka. W\xa0wolnych chwilach maluję, kleję, skrobię, dekoruję albo współtworzę muzykę z\xa0cudownymi kobietami. Śpiewanie w\xa0chórze daje mi poczucie wspólnoty i\xa0wzajemnego zaufania.",
    en: ""
  },
  dariaPisula: {
    pl: "W\xa0pracy jestem konsultantką księgową i\xa0programistką, w\xa0wolnym czasie zapaloną rowerzystką i\xa0hodowcą roślinek wszelakich. Od najmłodszych lat drugi alt, aczkolwiek bywało się też „tenorzycą”. Wrocławski Chór Żeński to idealne miejsce dla Super Babek - cieszę się, że mogę być jego częścią.",
    en: ""
  },
  hannaKonieczna: {
    pl: "Jestem pediatrą i\xa0lekarzem rehabilitacji, żoną i\xa0matką dwójki urwisów. Z\xa0zamiłowania rowerzystką, podróżniczką i\xa0chórzystką. Z\xa0wielką przyjemnością relaksuję się w\xa0kobiecym towarzystwie Wrocławskiego Chóru Żeńskiego, przy okazji licząc na ciągły rozwój duchowy.",
    en: ""
  },
  izabelaGrad: {
    pl: "Niepoprawna optymistka, w\xa0życiu pozachóralnym menedżerka kultury. Kocha ludzi, zwierzęta, las, rower, pracę, literki, obrazki i\xa0dźwięki - kolejność wybiera w\xa0zależności od okoliczności.",
    en: ""
  },
  joannaPolanska: {
    pl: "Jestem psycholożką sądową i\xa0pedagożką specjalną. Na co dzień realizuję się w pracy terapeutycznej z\xa0małymi dziećmi. Uwielbiam nurkować, jeździć na nartach i\xa0włóczyć się po świecie. Śpiewanie w chórze jest dla mnie odnajdywaniem siebie w harmonii, którą wspólnie tworzymy.",
    en: ""
  },
  katarzynaGemborys: {
    pl: "Artystka, projektantka. Specjalizuje się w\xa0projektowaniu szkła użytkowego oraz unikatowego, jak i\xa0biżuterii. Koneserka obrazu i\xa0dźwięku, fanka tatuażu i\xa0kaligrafii. Miłośniczka kawy w\xa0różnych wersjach.",
    en: ""
  },
  katarzynaRadon: {
    pl: "Wokalistka, psycholog, logopeda, na co dzień nauczyciel śpiewu i\xa0gry na instrumentach klawiszowych, od święta aktorka epizodystka, chórzystka, śpiewaczka.",
    en: ""
  }
}
