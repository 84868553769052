export const iisopranos = {
  alicjaTrela: {
    pl: "Z wykształcenia śpiewaczka i\xa0technolożka żywności, z\xa0zawodu marketerka i\xa0menadżerka projektów. Wrocławski Chór Żeński daje mi przestrzeń do realizowania swojej pasji oraz możliwość przebywania na co dzień z\xa0cudownymi kobietami.",
    en: ""
  },
  annaBudekKorczagin: {
    pl: "Śpiewanie w\xa0chórze pozwala mi porządnie odetchnąć. Od zawsze gra mi w\xa0głowie, podśpiewuję i\xa0mruczę. Współtworzenie muzyki utwierdza mnie w\xa0przekonaniu, że mój głos jest ważny.",
    en: ""
  },
  annaSeidelMydlowska: {
    pl: "Architekt krajobrazu, grafik komputerowy spełniający się artystycznie w branży ślubnej i\xa0kosmetycznej. W\xa0pracy ciągle nuci pod nosem lub gwiżdże. Dobrze, że po pracy może pośpiewać w\xa0chórze.",
    en: ""
  },
  joannaKobylinska: {
    pl: "Śpiew w\xa0chórze daje mi wytchnienie i\xa0satysfakcję, którą kocham i\xa0nie zamierzam z\xa0niej rezygnować. Dodatkowo jestem logopedką poszerzającą ciągle swoje horyzonty, a\xa0kiedyś specjalistką od stosunków międzynarodowych. Po cichu fanką płyt CD i\xa0DVD oraz szczęśliwą i\xa0kochaną kobietą.",
    en: ""
  },
  martynaPieniazek: {
    pl: "Jestem zakochaną w\xa0lesie i\xa0ziołach przyrodniczką, ciepłolubną joginką, miłośniczką górskich wędrówek, zwłaszcza po Azji Mniejszej, a\xa0także okulistką, lekarką misyjną, zadurzoną po uszy w\xa0neurookulistyce. Śpiew chóralny daje mi mistyczne przeżycie współtworzenia sztuki, bycia wewnątrz dźwięku. Wrocławski Chór Żeński to krąg kobiet splatających swe emocje, ale i\xa0organiczny aparat głosowy tworzący w\xa0każdym utworze ulotną chwilę Piękna, zawsze inną, zawsze ważną.",
    en: ""
  },
  paulaSobok: {
    pl: "Śpiewanie w\xa0chórze uzależnia…",
    en: ""
  },
  paulinaLopatka: {
    pl: "Ironiczno-sympatyczna, od zawsze wie, że jest jednym wielkim chodzącym paradoksem. Często cytuje Kapelusznika, twierdząc, że tylko „wariaci są coś warci”. Z\xa0wykształcenia literaturoznawca, quasi-historyk i\xa0niemalże muzykolog. Z\xa0zawodu nauczyciel polskiego i\xa0historii. Z\xa0myśli neoplatonistka i\xa0postmodernistka. Śpiewanie jest katalizatorem emocji, doświadczeń i\xa0przeżyć, więc nierzadko śpiewa bez przerwy. Tańczy swinga i\xa0nie tylko...",
    en: ""
  },
  sylwiaWrzeszcz: {
    pl: "Z zawodu jestem muzykiem, z\xa0wykształcenia muzykologiem. Z wyboru żoną i\xa0mamą. Wraz z\xa0mężem współtworzę markę 1000papryk, uprawiając niespotykane dotąd rodzaje ostrych papryczek. Jestem entuzjastką roślin, rowerowych wycieczek i\xa0makramową twórczynią. Śpiew w\xa0Wrocławskim Chórze Żeńskim to radość, spełnienie, emocje i\xa0miłość od pierwszych wspólnych dźwięków.",
    en: ""
  }
}
