import { Injector } from "../../di/Injector"
import { noop } from "../../saga/noop"
import { BlackBoxGenerator } from "../../saga/types"
import { setIsFocused } from "../actions"

export function* watchFocusChange({ win, store }: Injector): BlackBoxGenerator {
  win.addEventListener("focus", () => {
    store.dispatch(setIsFocused(true))
  })

  win.addEventListener("blur", () => {
    store.dispatch(setIsFocused(false))
  })

  yield* noop()
}
