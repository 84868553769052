/** @jsxImportSource @emotion/react */
import conductor_transparent from "../../../images/conductor/conductor_transparent.png"
import { useTheme } from "../../design/hooks/useTheme"
import { P } from "../../design/system/P"
import { H1 } from "../../design/system/headers/H1"

export const ConductorPage: React.FC = () => {
  const { colors } = useTheme()

  return (
    <section
      css={{
        paddingTop: 20,
        width: "100%"
      }}
    >
      <H1 color={colors.primary[300]} textAlign="left">
        IZABELA POLAKOWSKA-RYBSKA
      </H1>
      <img
        css={{
          shapeOutside: `url(${conductor_transparent})`,
          shapeImageThreshold: "0.7",
          shapeMargin: "3.8% !important",
          width: "35%",
          float: "right",
          margin: "0px 0 0",
          padding: "0 5px 0"
        }}
        alt="Conductor Izabela Polakowska-Rybska"
        src={conductor_transparent}
      />
      <P justify>
        Ukończyła Ogólnokształcącą Szkołę Muzyczną I i II st. im. Karola Szymanowskiego we Wrocławiu w klasie skrzypiec. Jest absolwentką
        Akademii Muzycznej im. Karola Lipińskiego we Wrocławiu: Wydziału Edukacji Muzycznej w klasie dyrygowania Agnieszki Franków-Żelazny
        oraz Wydziału Kompozycji, Dyrygentury, Teorii Muzyki i Muzykoterapii w klasie dyrygentury Alana Urbanka.
      </P>
      <P justify>
        W latach 2007-2012 pełniła funkcję Asystenta Dyrygenta w Chórze Medici Cantantes Uniwersytetu Medycznego we Wrocławiu. W latach
        2010-2014 prowadziła Chór przy Zespole Szkół nr 14 we Wrocławiu. W latach 2012-2013 była Dyrygentem i Opiekunem Artystycznym Chóru
        Dziecięcego Opery Wrocławskiej. Od 2013 r. gościnnie pełni funkcję Dyrygenta-Asystenta w Polskim Narodowym Chórze Młodzieżowym. W
        latach 2013-2015 pełniła obowiązki Dyrektora Artystycznego Chóru Polskiego Radia. W latach 2016-2018 była Dyrygentem i Kierownikiem
        Artystycznym Chóru Filharmonii Opolskiej. Od grudnia 2016 r. do kwietnia 2017 r. pełniła funkcję Zastępcy Kierownika Artystycznego
        Chóru Chłopięcego Narodowego Forum Muzyki. W roku 2017 była Asystentem Dyrygenta w Chórze Narodowego Forum Muzyki. W styczniu 2018
        r. została Kuratorem Regionalnym Akademii Chóralnej – Śpiewającej Polski. W marcu 2018 r. założyła Męski Zespół Wokalny I Signori.
        Od 2018 r. prowadzi przedmiot &quot;Śpiew Zespołowy&quot; na Wydziale Wokalnym Akademii Muzycznej im. Karola Lipińskiego we
        Wrocławiu. W czerwcu 2021 r. założyła Wrocławski Chór Żeński. We wrześniu 2022 r. została Dyrektorem Artystycznym i Programowym
        Śpiewającej Polski.
      </P>
      <P justify>
        Mimo młodego wieku w swoim dorobku posiada różne nagrody i osiągnięcia: I miejsce na Międzynarodowym Konkursie Zespołów Wokalnych
        Stonavská Barbórka, Wyróżnienie oraz Nagrodę Dla Najlepszego Polskiego Uczestnika Turnieju podczas V Międzynarodowego Turnieju
        Dyrygentury Chóralnej W STRONĘ POLIFONII, III Nagrodę, Nagrodę Specjalną za Wyróżniającą się Interpretację Muzyki Współczesnej
        ufundowaną i przyznaną przez Katedrę Chóralistyki i Prowadzenia Zespołów Akademii Muzycznej im. Karola Lipińskiego we Wrocławiu,
        Nagrodę Specjalną ufundowaną i przyznaną przez Katedrę Dyrygentury Chóralnej oraz Członków Chóru Akademii Muzycznej im. Karola
        Szymanowskiego w Katowicach podczas VII Międzynarodowego Turnieju Dyrygentury Chóralnej W STRONĘ POLIFONII.
      </P>
      <P justify>
        Wraz z Chórem Polskiego Radia gościła m.in. na: Gali Finałowej Koryfeusz Muzyki Polskiej, Festiwalu Warszawska Jesień, Sacrum
        Profanum, Festiwalu Witolda Lutosławskiego Łańcuch, Wielkanocnym Festiwalu Ludwiga van Beethovena w Warszawie, Edinburgh
        International Festival. Przygotowała zespół do nagrań trzech płyt z muzyką polskich kompozytorów: Krzysztof Penderecki. Utwory
        Chóralne, Próg nadziei - W hołdzie Janowi Pawłowi II, Litanie Ostrobramskie – Stanisława Moniuszki.
      </P>
    </section>
  )
}
