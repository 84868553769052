import agataLuboinska from "../../../../images/voices/lowres/isopranos/AgataLuboinska.jpg"
import aleksandraWozniak from "../../../../images/voices/lowres/isopranos/AleksandraWozniak.jpg"
import annaAleksandrowicz from "../../../../images/voices/lowres/isopranos/AnnaAleksandrowicz.jpg"
import annaMarcinek from "../../../../images/voices/lowres/isopranos/AnnaMarcinek.jpg"
import dominikaUrban from "../../../../images/voices/lowres/isopranos/DominikaUrban.jpg"
import kamilaMadej from "../../../../images/voices/lowres/isopranos/KamilaMadej.jpg"
import nataliaMutor from "../../../../images/voices/lowres/isopranos/NataliaMutor.jpg"
import olenaDziurdz from "../../../../images/voices/lowres/isopranos/OlenaDziurdz.jpg"
import { isopranos } from "./descriptions/isopranos"
import { Voice } from "./parts/Voice"
import { VoiceSection } from "./parts/VoiceSection"

export const ISopranos: React.FC = () => {
  return (
    <VoiceSection name="I SOPRANY">
      <Voice
        description={isopranos.agataLuboinska.pl}
        highResPhoto="/isopranos/AgataLuboinska"
        firstName="Agata"
        lastName="Luboińska"
        photo={agataLuboinska}
      />
      <Voice
        description={isopranos.aleksandraWozniak.pl}
        highResPhoto="/isopranos/AleksandraWozniak"
        firstName="Aleksandra"
        lastName="Woźniak"
        photo={aleksandraWozniak}
      />
      <Voice
        description={isopranos.annaAleksandrowicz.pl}
        highResPhoto="/isopranos/AnnaAleksandrowicz"
        firstName="Anna"
        lastName="Aleksandrowicz"
        photo={annaAleksandrowicz}
      />
      <Voice
        description={isopranos.annaMarcinek.pl}
        highResPhoto="/isopranos/AnnaMarcinek"
        firstName="Anna"
        lastName="Marcinek"
        photo={annaMarcinek}
      />
      <Voice
        description={isopranos.dominikaUrban.pl}
        highResPhoto="/isopranos/DominikaUrban"
        firstName="Dominika"
        lastName="Urban"
        photo={dominikaUrban}
      />
      <Voice
        description={isopranos.kamilaMadej.pl}
        highResPhoto="/isopranos/KamilaMadej"
        firstName="Kamila"
        lastName="Madej"
        photo={kamilaMadej}
      />
      <Voice
        description={isopranos.nataliaMutor.pl}
        highResPhoto="/isopranos/NataliaMutor"
        firstName="Natalia"
        lastName="Mutor"
        photo={nataliaMutor}
      />
      <Voice
        description={isopranos.olenaDziurdz.pl}
        highResPhoto="/isopranos/OlenaDziurdz"
        firstName="Olena"
        lastName="Dziurdź"
        photo={olenaDziurdz}
      />
    </VoiceSection>
  )
}
