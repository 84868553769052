/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"
import { useScreen } from "../../../screen/hooks/useScreen"
import { useTheme } from "../../hooks/useTheme"
import { HProps } from "./HProps"

export const H1: React.FC<PropsWithChildren<HProps>> = ({
  alignSelf,
  children,
  color,
  letterSpacing,
  marginBottom,
  marginTop,
  textAlign
}) => {
  const { colors, fonts } = useTheme()
  const { window } = useScreen()

  return (
    <h1
      css={{
        alignSelf,
        color: color || colors.black,
        fontFamily: fonts.lato.name,
        fontSize: window.width < 1120 ? 30 : 35,
        letterSpacing,
        margin: "0 0 8px 0",
        marginBottom,
        marginTop,
        textAlign
      }}
    >
      {children}
    </h1>
  )
}
