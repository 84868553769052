import { PropsWithChildren, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useInjector } from "../injector/hooks/useInjector"

export const ModalElement: React.FC<PropsWithChildren> = ({ children }) => {
  const [element, setElement] = useState<Element | null>(null)
  const { doc } = useInjector()

  useEffect(() => {
    const div = doc.createElement("div")
    doc.body.appendChild(div)
    setElement(div)

    return () => {
      div.remove()
      setElement(null)
    }
  }, [doc, setElement])

  return element != null ? createPortal(children, element) : null
}
