/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react"

const removeElementByIds = (ids: Array<string>): void => {
  ids.forEach((id) => {
    const element = document.getElementById(id)
    if (element && element.parentNode) {
      element.parentNode.removeChild(element)
    }
  })
}

export class Messenger extends React.Component {
  ref = React.createRef<HTMLDivElement>()

  setFbAsyncInit(): void {
    //@ts-ignore
    window.fbAsyncInit = () => {
      //@ts-ignore
      FB.init({
        xfbml: true,
        version: "v15.0"
      })
    }
  }

  loadSDKAsynchronously = (): void => {
    /* eslint-disable */
    ;(function (d, s, id, lang) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      //@ts-ignore
      js.src = `https://connect.facebook.net/${lang}/sdk/xfbml.customerchat.js`
      //@ts-ignore
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk", "pl_PL")
    /* eslint-enable */
  }

  removeFacebookSDK(): void {
    removeElementByIds(["facebook-jssdk", "fb-root"])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    delete window.FB
  }

  reloadSDKAsynchronously = (): void => {
    this.removeFacebookSDK()
    this.loadSDKAsynchronously()
  }

  componentDidMount(): void {
    this.setFbAsyncInit()
    this.reloadSDKAsynchronously()
  }

  componentWillUnmount(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (window.FB !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.FB.CustomerChat.hide()
    }
    this.ref.current?.remove()
    document.getElementById("fb-root")?.remove()
  }

  createMarkup(): { __html: string } {
    return {
      __html: `<div
        class="fb-customerchat"
        page_id="106843024986612"
        attribution="biz_inbox"
      ></div>`
    }
  }

  render(): JSX.Element {
    return <div key={Date()} ref={this.ref} dangerouslySetInnerHTML={this.createMarkup()} />
  }
}
