import { FormikHelpers } from "formik"
import { payloadAction } from "../action/creators"
import { SendContactEmailData } from "./EmailService"

const prefix = "EMAIL/SEND"

export type SendContactEmailPayload = {
  data: SendContactEmailData
  formHelpers: FormikHelpers<SendContactEmailData>
}

export const SEND_CONTACT_EMAIL = `${prefix}/CONTACT`
export const sendContactEmail = payloadAction<SendContactEmailPayload>(SEND_CONTACT_EMAIL)
