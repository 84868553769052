/** @jsxImportSource @emotion/react */

import { PropsWithChildren } from "react"
import { useTheme } from "../../design/hooks/useTheme"
import { useScreen } from "../../screen/hooks/useScreen"

export const NavElement: React.FC<PropsWithChildren> = ({ children }) => {
  const { fonts } = useTheme()
  const { window } = useScreen()

  return (
    <li
      css={{
        cursor: "pointer",
        fontFamily: fonts.lato.name,
        fontSize: window.width < 1000 ? 19 : 25,
        fontWeight: fonts.lato.weight["700"],
        lineHeight: "20px",
        marginRight: 8,
        padding: window.width < 750 ? "10px 4px" : "14px 8px"
      }}
    >
      {children}
    </li>
  )
}
