import { send, EmailJSResponseStatus } from "@emailjs/browser"

export type SendContactEmailData = {
  senderEmail: string
  senderName: string
  message: string
}

export class EmailService {
  constructor(private publicKey: string, private templates: { contact: string }, private serviceId: string) {}

  async sendContactEmail({ senderEmail, senderName, message }: SendContactEmailData): Promise<EmailJSResponseStatus> {
    return send(this.serviceId, this.templates.contact, { senderEmail, senderName, message }, this.publicKey)
  }
}
