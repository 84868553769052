/** @jsxImportSource @emotion/react */
import { Property } from "csstype"
import { PropsWithChildren } from "react"
import { Intent } from "../../../../modules/intent/Intent"
import { useTheme } from "../../../design/hooks/useTheme"
import { buttonCSS } from "./buttonCSS"

type Props = {
  disabled?: boolean
  float?: Property.Float
  intent: Intent
  onClick?: () => void
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  type?: "button" | "submit"
}

export const Button: React.FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  float,
  intent,
  onClick,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  type
}) => {
  const theme = useTheme()

  return (
    <button
      css={buttonCSS({
        disabled,
        float,
        intent,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        theme
      })}
      disabled={disabled}
      onClick={onClick}
      type={type || "button"}
    >
      {children}
    </button>
  )
}
