/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from "react"
import { useTheme } from "../../../../design/hooks/useTheme"
import { H1 } from "../../../../design/system/headers/H1"
import { useScreen } from "../../../../screen/hooks/useScreen"

type Props = {
  name: string
}

export const VoiceSection: React.FC<PropsWithChildren<Props>> = ({ children, name }) => {
  const { colors } = useTheme()
  const { window } = useScreen()
  const isForSmallScreen = window.width < 740
  const isForSmallestScreen = window.width < 470

  return (
    <section
      css={{
        paddingTop: 30,
        width: "100%"
      }}
    >
      <H1 color={colors.primary[300]} textAlign="left">
        {name}
      </H1>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: isForSmallScreen ? (isForSmallestScreen ? "repeat(3, 1fr)" : "repeat(4, 1fr)") : "repeat(6, 1fr)",
          rowGap: isForSmallScreen ? 20 : 30
        }}
      >
        {children}
      </div>
    </section>
  )
}
