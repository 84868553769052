import { Routes } from "../modules/routing/Routes"

export const routes: Routes = {
  index: () => "/",
  choir: () => "/chor",
  conductor: () => "/dyrygentka",
  events: {
    index: () => "/wydarzenia",
    details: (instanceId: string) => `/wydarzenia/szczegoly?id=${instanceId}`
  },
  contact: () => "/kontakt"
}
