/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { P } from "../../../../design/system/P"
import { VoiceModal } from "../voice-modal/VoiceModal"

type Props = {
  description: string
  firstName: string
  highResPhoto: string
  lastName: string
  photo: string
}

export const Voice: React.FC<Props> = ({ description, firstName, highResPhoto, lastName, photo }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  return (
    <>
      {isModalVisible && (
        <VoiceModal
          name={`${firstName} ${lastName}`}
          highResPhoto={highResPhoto}
          description={description}
          onClose={() => setIsModalVisible(false)}
        />
      )}
      <div
        css={{
          alignItems: "center",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          maxWidth: 130,
          "> p": {
            fontSize: 15,
            marginTop: "0 !important"
          },
          "&:hover": {
            "> img": {
              boxShadow: "0px 0px 24px -7px rgba(0, 0, 0, 1)"
            }
          }
        }}
        onClick={() => setIsModalVisible(true)}
      >
        <img
          css={{
            borderRadius: 8,
            transition: "box-shadow .3s"
          }}
          alt={`${firstName} ${lastName}`}
          height={105}
          src={photo}
          width={105}
        />
        <P>{firstName}</P>
        <P>{lastName}</P>
      </div>
    </>
  )
}
