import agataSkibinska from "../../../../images/voices/lowres/ialtos/AgataSkibinska.jpg"
import agnieszkaBereza from "../../../../images/voices/lowres/ialtos/AgnieszkaBereza.jpg"
import aleksandraMroczko from "../../../../images/voices/lowres/ialtos/AleksandraMroczko.jpg"
import dagmaraGrenik from "../../../../images/voices/lowres/ialtos/DagmaraGrenik.jpg"
import ewaLukasikKrol from "../../../../images/voices/lowres/ialtos/EwaLukasikKrol.jpg"
import ewaSkorska from "../../../../images/voices/lowres/ialtos/EwaSkorska.jpg"
import katarzynaSzeloch from "../../../../images/voices/lowres/ialtos/KatarzynaSzeloch.jpg"
import martaLaburda from "../../../../images/voices/lowres/ialtos/MartaLaburda.jpg"
import martaLemieszewska from "../../../../images/voices/lowres/ialtos/MartaLemieszewska.jpg"
import { ialtos } from "./descriptions/ialtos"
import { Voice } from "./parts/Voice"
import { VoiceSection } from "./parts/VoiceSection"

export const IAltos: React.FC = () => {
  return (
    <VoiceSection name="I ALTY">
      <Voice
        description={ialtos.agataSkibinska.pl}
        highResPhoto="/ialtos/AgataSkibinska"
        firstName="Agata"
        lastName="Skibińska"
        photo={agataSkibinska}
      />
      <Voice
        description={ialtos.agnieszkaBereza.pl}
        highResPhoto="/ialtos/AgnieszkaBereza"
        firstName="Agnieszka"
        lastName="Bereza"
        photo={agnieszkaBereza}
      />
      <Voice
        description={ialtos.aleksandraMroczko.pl}
        highResPhoto="/ialtos/AleksandraMroczko"
        firstName="Aleksandra"
        lastName="Mroczko"
        photo={aleksandraMroczko}
      />
      <Voice
        description={ialtos.dagmaraGrenik.pl}
        highResPhoto="/ialtos/DagmaraGrenik"
        firstName="Dagmara"
        lastName="Grenik"
        photo={dagmaraGrenik}
      />
      <Voice
        description={ialtos.ewaLukasikKrol.pl}
        highResPhoto="/ialtos/EwaLukasikKrol"
        firstName="Ewa"
        lastName="Łukasik-Król"
        photo={ewaLukasikKrol}
      />
      <Voice description={ialtos.ewaSkorska.pl} highResPhoto="/ialtos/EwaSkorska" firstName="Ewa" lastName="Skórska" photo={ewaSkorska} />
      <Voice
        description={ialtos.katarzynaSzeloch.pl}
        highResPhoto="/ialtos/KatarzynaSzeloch"
        firstName="Katarzyna"
        lastName="Szeloch"
        photo={katarzynaSzeloch}
      />
      <Voice
        description={ialtos.martaLaburda.pl}
        highResPhoto="/ialtos/MartaLaburda"
        firstName="Marta"
        lastName="Laburda"
        photo={martaLaburda}
      />
      <Voice
        description={ialtos.martaLemieszewska.pl}
        highResPhoto="/ialtos/MartaLemieszewska"
        firstName="Marta"
        lastName="Lemieszewska"
        photo={martaLemieszewska}
      />
    </VoiceSection>
  )
}
