/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { EventDetails } from "../../EventDetails"
import { EventDetailsQuery } from "../../EventsService.generated"

export const convertEventDetails = (input: Required<EventDetailsQuery>["wydarzenia"]): EventDetails => ({
  id: input!.id,
  title: input!.tytul,
  description: input!.opisWydarzenia.html,
  date: input!.dataWydarzenia,
  poster: input!.urlPlakatu || "xd"
})
