/** @jsxImportSource @emotion/react */
import { intlFormat } from "date-fns"
import { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { EventDetails as EventDetailsType } from "../../../../modules/domain/events/EventDetails"
import { clearEventDetails, getEventDetails } from "../../../../modules/domain/events/actions"
import { MainState } from "../../../../modules/state/MainState"
import { useTheme } from "../../../design/hooks/useTheme"
import { P } from "../../../design/system/P"
import { H1 } from "../../../design/system/headers/H1"
import { Error404 } from "../../errors/Error404"

const mapState = (state: MainState): EventDetailsType | null => state.domain.eventDetails

export const EventDetails: React.FC = () => {
  const eventDetails = useSelector(mapState, shallowEqual)
  const dispatch = useDispatch()
  const { colors, fonts } = useTheme()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get("id")

  useEffect(() => {
    if (instanceId) {
      dispatch(getEventDetails(instanceId))
    }
  }, [dispatch, instanceId])

  useEffect(() => {
    return () => {
      dispatch(clearEventDetails())
    }
  }, [dispatch])

  return instanceId != null ? (
    eventDetails != null ? (
      <section css={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <H1 color={colors.primary[300]} textAlign="left">
          {eventDetails.title}
        </H1>
        <P>
          {intlFormat(
            new Date(eventDetails.date),
            {
              day: "numeric",
              month: "long",
              year: "numeric"
            },
            {
              locale: "pl"
            }
          )}
        </P>
        {eventDetails.poster && <img alt="Poster" src={eventDetails.poster} width={400} css={{ alignSelf: "center" }} />}
        <div dangerouslySetInnerHTML={{ __html: eventDetails.description }} css={{ fontFamily: fonts.lato.name }}></div>
      </section>
    ) : (
      <P>Ładowanie...</P>
    )
  ) : (
    <Error404 />
  )
}
