import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { useTheme } from "../../../design/hooks/useTheme"

type IconProps = {
  icon: FontAwesomeIconProps["icon"]
}

export const Icon: React.FC<IconProps> = ({ icon }) => {
  const { colors } = useTheme()
  const iconSize: FontAwesomeIconProps["size"] = "2x"

  return <FontAwesomeIcon icon={icon} size={iconSize} color={colors.primary[300]} />
}
