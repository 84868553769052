/** @jsxImportSource @emotion/react */
import { useTheme } from "../../../design/hooks/useTheme"
import { P } from "../../../design/system/P"
import { MainSection } from "./parts/MainSection"

export const MainVideo: React.FC = () => {
  const { fonts } = useTheme()
  return (
    <MainSection sectionTitle="KOCHAMY ŚPIEW. POSŁUCHAJ NAS...">
      <iframe
        src="https://fast.wistia.net/embed/iframe/ulpuc3gfem"
        title="Wrocławski Chór Żeński - Muzycy Medykom Video"
        allow="autoplay; fullscreen"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        allowtransparency="true"
        frameBorder={0}
        className="wistia_embed"
        name="wistia_embed"
        allowFullScreen
        css={{
          height: "40vh"
        }}
      ></iframe>
      <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
      <P paddingTop="20px">
        więcej nagrań znajdziesz{" "}
        <a
          css={{ fontWeight: fonts.lato.weight["700"] }}
          href="https://www.youtube.com/channel/UCPxSTvuqNtMCgn6-Gg1TtCA"
          target="_blank"
          rel="noreferrer"
        >
          {"> TUTAJ <"}
        </a>
      </P>
    </MainSection>
  )
}
