/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom"
import logo from "../../../images/logo/logo.jpg"
import { useTheme } from "../../design/hooks/useTheme"
import { H1 } from "../../design/system/headers/H1"
import { useRoutes } from "../../routes/hooks/useRoutes"
import { useScreen } from "../../screen/hooks/useScreen"
import { IconGroup } from "./icons/IconGroup"

export const TopBar: React.FC = () => {
  const { colors } = useTheme()
  const { window } = useScreen()
  const routes = useRoutes()
  const isForSmallScreen = window.width < 650

  return (
    <header>
      <div
        css={{
          borderBottomColor: colors.primary[300],
          borderBottomStyle: "solid",
          borderBottomWidth: isForSmallScreen ? 3 : 6,
          display: "flex",
          flexDirection: "row",
          height: isForSmallScreen ? 85 : 135,
          justifyContent: "center"
        }}
      >
        <Link to={routes.index()}>
          <img alt="Choir logo" height={isForSmallScreen ? 75 : 125} src={logo} css={{ paddingRight: isForSmallScreen ? 0 : 50 }} />
        </Link>
        {!isForSmallScreen && (
          <Link css={{ alignSelf: "center" }} to={routes.index()}>
            <H1 alignSelf="center" letterSpacing="7px" textAlign="center">
              WROCŁAWSKI CHÓR ŻEŃSKI
            </H1>
          </Link>
        )}
        {!isForSmallScreen && <IconGroup />}
      </div>
    </header>
  )
}
